<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header />
    <div class="fancy-hero-contact bg-doc space-fix">
      <div class="bg-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
              <h2>Contact us</h2>
              <h1 class="font-gordita-bold">Feel free to contact us or just say hi!</h1>
            </div>
          </div>
        </div>
      </div> <!-- /.bg-wrapper -->
    </div>
    <div class="contact-style-two">
      <div class="container">
        <div class="contact-info-wrapper">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    :src="require(`@/assets/images/icon/44.svg`)"
                    alt=""
                  >
                </div>
                <div class="title">
                  Location
                </div>
                <p class="font-gordita">
                  Minneapolis <br> Minnesota
                </p>
              </div> <!-- /.address-info -->
            </div>
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    :src="require(`@/assets/images/icon/45.svg`)"
                    alt=""
                  >
                </div>
                <div class="title">
                  Contact
                </div>
                <p class="font-gordita">
                  heugroup@gmail.com <br>
                </p>
              </div> <!-- /.address-info -->
            </div>
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    :src="require(`@/assets/images/icon/46.svg`)"
                    alt=""
                  >
                </div>
                <div class="title">
                  Social Media
                </div>
                <p class="font-gordita">
                  Find on social media
                </p>
                <ul class="d-flex justify-content-center">
                  <li><a href="https://www.facebook.com/heubot/"><i
                    class="fa fa-facebook"
                    aria-hidden="true"
                  /></a></li>
                  <li><a href="twitter.com/heubot"><i
                    class="fa fa-twitter"
                    aria-hidden="true"
                  /></a></li>
                </ul>
              </div> <!-- /.address-info -->
            </div>
          </div>
          <img
            :src="require(`@/assets/images/shape/64.svg`)"
            alt=""
            class="shapes shape-one"
          >
        </div> <!-- /.contact-info-wrapper -->

        <div class="form-style-classic mt-150 md-mt-80">
          <form
            id="contact-form"
            data-bs-toggle="validator"
            @submit.prevent="onSubmit"
          >
            <div class="messages" />
            <div class="row controls">
              <div
                class="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div class="input-group-meta form-group mb-60">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Michel"
                    name="Fname"
                    required="required"
                    data-error="Name is required."
                  >
                  <div class="help-block with-errors" />
                </div>
              </div>
              <div
                class="col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div class="input-group-meta form-group mb-60">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Hawkins"
                    name="Lname"
                    required="required"
                    data-error="Name is required."
                  >
                  <div class="help-block with-errors" />
                </div>
              </div>
              <div
                class="col-12"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="input-group-meta form-group mb-60">
                  <label>Email Address</label>
                  <input
                    type="email"
                    placeholder="saeslal@zouj.co.uk"
                    name="email"
                    required="required"
                    data-error="Valid email is required."
                  >
                  <div class="help-block with-errors" />
                </div>
              </div>
              <div
                class="col-12"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="input-group-meta form-group lg mb-40">
                  <label>Your Message</label>
                  <textarea
                    placeholder="your message here.."
                    name="message"
                    required="required"
                    data-error="Please,leave us a message."
                  />
                  <div class="help-block with-errors" />
                </div>
              </div>
              <div
                class="col-12"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <button :class="`${contact_doc ? 'theme-btn-home lg' : 'theme-btn-home'}`">
                  Send Message
                </button></div>
            </div>
          </form>
        </div> <!-- /.form-style-classic -->
      </div>
    </div>
    <Footer />
  </div>

</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'PortfolioDetails',
  components: {
    Header,
    Footer,
  },
}
</script>
<style scoped>
@import 'css/custom.css';
@import 'css/style.css';
@import 'css/custom-animation.css';
@import 'css/framework.css';
@import 'css/menu.css';
@import 'css/responsive.css';

h2 {
  font-family: "gordita" !important;
  font-size: 25px !important;
}

p{
  font-family: "gordita" !important;
  font-size: 18px !important;
}
li{
  font-family: "gordita" !important;
  font-size: 18px !important;
  color: rgb(84, 84, 84);
}

</style>
